import GET_ARTICLES from "../class/getArticles";
import Swiper from "swiper/bundle";
import GET_INSTAGRAM from '../class/getInstagram'

const [d] = [document];

class BLOCKS {
  constructor () {
    this.init();
  }
  
  init () {
    this.mainvisual();
    this.headline('.block-headline__article[data-plugin="getArticles"]');
    this.products('.block-products__list[data-plugin="getArticles"]');
    this.handson('.block-handson__list[data-plugin="getArticles"]');
    this.news('.block-news__list[data-plugin="getArticles"]');
  }

  mainvisual () {
    //メインスライダー
    new Swiper('.mainvisual-slider[data-plugin="mainSlider"]',{
      direction: 'vertical',
      loop: true,
      effect: 'fade',
      speed: 1200,
      fadeEffect: {
        crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
      },
      autoplay:{
        delay: 4000,
        disableOnInteraction: false,
        waitForTransition: false
      },
    });
  }

  headline (q) {
    const getArticle = new GET_ARTICLES(q);
    getArticle.makeItem = (item,content) => `
      <a href="${item.href}" class="block-headline__link">
        <span class="date">${item.date}</span>
        <span class="cate">${item.category.name}</span>
        <h3 class="subject">${item.title.str_truncate(30)}</h3>
      </a>`;
    getArticle.render();
  }

  products (q) {
    const getArticle = new GET_ARTICLES(q);
    getArticle.makeItem = item => `
      <a href="${item.href}" class="products-item">
        <figure class="products-item__img"><img src="${item.thumb}" width="135" height="135" alt="${item.title}" /></figure>
        <h3 class="subject">${item.title.str_truncate(30)}</h3>
        <span class="desc">${item.desc.str_truncate(20)}</span>
      </a>`;
    getArticle.render();
  }

  handson (q) {
    const getArticle = new GET_ARTICLES(q);
    getArticle.makeItem = item => `
      <li class="handson-item">
        <a href="${item.href}" class="handson-item__link">
          <figure class="handson-item__img"><img src="${item.thumb}" width="360" height="360" alt="${item.title}" /></figure>
          <div class="handson-item__body">
            <h3 class="subject">${item.title.stripTags().str_truncate(80)}</h3>
            <span class="desc">${item.desc.stripTags().str_truncate(80)}</span>
            <span class="body">${item.body.stripTags().str_truncate(120)}</span>
          </div>
        </a>
      </li>`;
    getArticle.render().then( ([[items]]) => {
      if( items.length == 0 ) d.querySelector(q).insertAdjacentHTML('beforeEnd', `
        <p class="p-5 mb-0 text-center">
          Coming soon...
        </p>
      `)
    });
  }


  news (q) {
    const getArticle = new GET_ARTICLES(q);
    const getCategories = this.fetchCategories('news');
    const categoryContainer = d.querySelectorAll('.block-news [data-plugin="getCategories"]');
    getArticle.makeItem = item => `
      <a href="${item.href}" class="block-news__item">
        <span class="cate">${item.category.name}</span>
        <span class="date">${item.date}</span>
        <span class="title${item.is_new? ' is-new': ''}">${item.title.str_truncate(30)}</span>
        <!-- /.block-news__body -->
      </a>`;
    getArticle.render().then( ([[art,cat]]) => {
      if( art.length < 1 ) {
        d.querySelectorAll( q ).forEach( obj => {
          let parent = obj.dataset.parent? obj.closest( obj.dataset.parent ): '';
          if(parent) parent.remove();
        });
      }
    });
    getCategories.then( cats => {
      let cat = {
        all: {
          id: 'all', href: '/news/?mode=list', name: 'ALL', turn: 0
        },
        other: {
          id: 'other', href: '/news/?mode=list&cat=0', name: 'その他', turn: 99
        }
      }
      cats = [ cat.all, ...cats, cat.other ];
      const makeHTML = cat => `<li class="block-news__categories-item is-${cat.id}"><a href="${cat.href}">${cat.name}</a></li>`;
      categoryContainer.forEach( obj => {
        obj.innerHTML = '';
        cats.forEach( cat => obj.insertAdjacentHTML('beforeend', makeHTML(cat) ) );
      });
    });

  }

  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

}

const domReady = TPL => {
  new BLOCKS;
};

const jqInit = () => {
    // Instagram
    GET_INSTAGRAM();

}

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => jqInit());
}