import GET_ARTICLES from "../class/getArticles";

const [d] = [document];

class BLOCKS {
  constructor () {
    this.init()
  }

  init () {
    this.handson('.block-handson__list[data-plugin="getArticles"]');
    this.news('.block-news-thumb__list[data-plugin="getArticles"]');
  }

  handson (q) {
    const getArticle = new GET_ARTICLES(q);
    getArticle.makeItem = item => `
      <li class="handson-item">
        <a href="${item.href}" class="handson-item__link">
          <figure class="handson-item__img"><img src="${item.thumb}" width="360" height="360" alt="${item.title}" /></figure>
          <div class="handson-item__body">
            <h3 class="subject">${item.title.stripTags().str_truncate(80)}</h3>
            <span class="desc">${item.desc.stripTags().str_truncate(80)}</span>
            <span class="body">${item.body.stripTags().str_truncate(120)}</span>
          </div>
        </a>
      </li>`;
    getArticle.render().then( ([[items]]) => {
      if( items.length == 0 ) d.querySelector(q).insertAdjacentHTML('beforeEnd', `
        <p class="p-5 mb-0 text-center">
          Coming soon...
        </p>
      `)
    });
  }

  news (q) {
    const getArticle = new GET_ARTICLES(q);
    getArticle.makeItem = item => `
      <a href="${item.href}" class="block-news-thumb__item">
        <figure class="block-news-thumb__item-img">
          <img src="${item.thumb}" alt="${item.title}" width="222" height="222" />
        </figure>
        <!-- /.block-news-thumb__img -->
        <div class="block-news-thumb__item-body">
          <span class="cate">${item.category.name}</span>
          <span class="date">${item.date}</span>
          <span class="title${item.is_new? ' is-new': ''}">${item.title.str_truncate(30)}</span>
        </div>
        <!-- /.block-news-thumb__body -->
      </a>`;
    getArticle.render().then( ([[art,cat]]) => {
      if( art.length < 1 ) {
        d.querySelectorAll( q ).forEach( obj => {
          let parent = obj.dataset.parent? obj.closest( obj.dataset.parent ): '';
          if(parent) parent.remove();
        });
      }
    });

  }

}
const domReady = TPL => {
  new BLOCKS;
};

export default function ONIONHOUSE () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

}